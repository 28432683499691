import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Navbar from './components/Navbar';
import Marketplace from './components/Marketplace';
import CreateToken from './components/CreateToken';
import SearchResults from './components/SearchResults';
import Login from './components/Login';
import Profile from './components/Profile';
import TermsAndConditions from './components/TermsandConditions';
import TokenDetailsPage from './components/TokenDetailsPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Marketplace />} />
          <Route path="/token/:tokenId" element={<TokenDetailsPage />} />
          <Route path="/create-token" element={<CreateToken />} />
          <Route path="/search-results" element={<SearchResults />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/terms" element={<TermsAndConditions />} /> {/* Add this route */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
