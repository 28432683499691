import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Grid, Typography, Dialog, DialogActions, DialogContent,
  DialogTitle, TextField, Snackbar, Alert, Box, MenuItem, Select, FormControl, InputLabel,
  Button, Avatar, IconButton, LinearProgress, Tooltip, InputAdornment
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import StarIcon from '@mui/icons-material/Star';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import LanguageIcon from '@mui/icons-material/Language';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  ArcElement,
  RadialLinearScale
} from 'chart.js';

import {
  Line, Bar, Doughnut, Pie, Radar, PolarArea, Bubble, Scatter
} from 'react-chartjs-2';

import TermsAndConditions from "./TermsandConditions";
import MarketplaceAppBar from './MarketplaceAppBar';
import CustomPagination from './CustomPagination';
import Ticker from './Ticker'; 
import TokenDetailsTabs from './TokenDetailsTabs';
import { fetchAndCacheSolPrice } from '../utils/solPriceCache';
import { WalletContext } from '../context/WalletContext';
import { AuthContext } from '../context/AuthContext';
import CandlestickChart from './CandlestickChart';
import { useWallet } from '@solana/wallet-adapter-react';
import { Connection, Transaction, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { calculateTokensReceived } from './bondingCurve';

ChartJS.register(
  CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title,
  ChartTooltip, Legend, ArcElement, RadialLinearScale, TimeScale
);

function Marketplace() {
  const { publicKey, signTransaction } = useWallet();
  const [tokens, setTokens] = useState([]);
  const [estimatedTokens, setEstimatedTokens] = useState(0);
  const [estimatedSolReceived, setEstimatedSolReceived] = useState(0);
  const [selectedToken, setSelectedToken] = useState(null);
  const [historicalData, setHistoricalData] = useState([]);
  const [ohlcData, setOhlcData] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [purchaseAmount, setPurchaseAmount] = useState(0);
  const [sellAmount, setSellAmount] = useState(0);
  const [message, setMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [chartType, setChartType] = useState('Line');
  const [howItWorksOpen, setHowItWorksOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [solPrice, setSolPrice] = useState(180);
  const [buyError, setBuyError] = useState(false);
  const [sellError, setSellError] = useState(false);
  const { address, wallet } = useContext(WalletContext);
  const { user } = useContext(AuthContext);
  const [filter, setFilter] = useState('new');
  const [page, setPage] = useState(1); // Manage the current page state

  // const marketCapThreshold = 400 * solPrice;

  const DEFAULT_PRICE = 180.00;
  const A = 75;
  const N = 50000;
 

  const navigate = useNavigate();
  
  // Fetch SOL price
  useEffect(() => {
    const fetchSolPrice = async () => {
      try {
        const response = await axios.get('https://backend.memebrewery.fun/api/sol-price', { withCredentials: true });
        setSolPrice(response.data.solPrice);
      } catch (error) {
        console.error('Error fetching SOL price:', error);
      }
    };
    fetchSolPrice();
  }, []);

  // Fetch tokens based on filter and pagination
  useEffect(() => {
    const fetchFilteredTokens = async () => {
      try {
        const response = await axios.get(`https://backend.memebrewery.fun/api/tokens?page=${currentPage}&filter=${filter}`, { withCredentials: true });
        setTokens(response.data.tokens);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching filtered tokens:', error);
      }
    };
    fetchFilteredTokens();
  }, [currentPage, filter]);

  useEffect(() => {
    fetchTokens();
    // fetchTopTokens();
    // fetchTopHolders();
  }, []);

  const handleFilter = useCallback((newFilter) => {
    setFilter(newFilter);
    setCurrentPage(1);
  }, []);

  const fetchTokens = async (query = '', page = 1, pageSize = 100) => {
    try {
      const token = localStorage.getItem('auth-token');

      // Construct the URL correctly based on whether a query is provided
      const baseUrl = 'https://backend.memebrewery.fun/api/tokens';
      const url = `${baseUrl}?page=${page}&pageSize=${pageSize}${query ? `&${query}` : ''}`;

      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      console.log('Response data:', response.data);

      if (!response.data || !response.data.tokens) {
        throw new Error('Invalid response structure');
      }

      const { tokens, totalPages, currentPage } = response.data;

      setTokens(tokens);
      setTotalPages(totalPages);
      setCurrentPage(currentPage);
    } catch (error) {
      console.error('Error fetching tokens:', error);
    }
  };

//   const fetchTokens = async (query = '', page = 1, pageSize = 100) => {
//     try {
//       const token = localStorage.getItem('auth-token');

//       // Construct the URL correctly based on whether a query is provided
//       const baseUrl = 'https://backend.memebrewery.fun/api/tokens';
//       const url = `${baseUrl}?page=${page}&pageSize=${pageSize}${query ? `&${query}` : ''}`;

//       const response = await axios.get(url, {
//         withCredentials: true,
//         headers: {
//           'Authorization': `Bearer ${token}`,
//         },
//       });

//       console.log('Response data:', response.data);

//       if (!response.data || !response.data.tokens) {
//         throw new Error('Invalid response structure');
//       }

//       const { tokens, totalPages, currentPage } = response.data;

//       setTokens(tokens);
//       setTotalPages(totalPages);
//       setCurrentPage(currentPage);
//     } catch (error) {
//       console.error('Error fetching tokens:', error);
//     }
//   };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    const query = event.target.elements.search.value.trim();
    navigate('/search-results', { state: { query } });
  };

  const handleCardClick = (token) => {
    // Navigate to token detail page
    navigate(`/token/${token.id}`);
  };

  const handlePurchase = async () => {
    try {
      const token = localStorage.getItem('auth-token');
      const response = await axios.post('https://backend.memebrewery.fun/api/buy', { tokenId: selectedToken.id, amount: purchaseAmount }, {
        withCredentials: true,
        headers: { 'Authorization': `Bearer ${token}` },
      });

      const { transaction: base64Transaction } = response.data;
      const transaction = Transaction.from(Buffer.from(base64Transaction, 'base64'));
      const signedTransaction = await signTransaction(transaction);
      const serializedSignedTransaction = signedTransaction.serialize().toString('base64');

      await axios.post('https://backend.memebrewery.fun/api/sign-and-send', {
        signedTransaction: serializedSignedTransaction,
        tokenId: selectedToken.id,
        amount: purchaseAmount,
        phase: 'buy'
      }, {
        withCredentials: true,
        headers: { 'Authorization': `Bearer ${token}` },
      });

      setMessage('Purchase successful!');
      setSnackbarOpen(true);
      setSelectedToken(null);
      setPurchaseAmount(0);
      setEstimatedTokens(0);

      await fetchTokens();

    } catch (error) {
      setMessage('Purchase failed: ' + (error.response?.data?.error || error.message));
      setSnackbarOpen(true);
    }
  };

  const handleSell = async () => {
    try {
      const token = localStorage.getItem('auth-token');
      const response = await axios.post('https://backend.memebrewery.fun/api/sell', { tokenId: selectedToken.id, amount: sellAmount }, {
        withCredentials: true,
        headers: { 'Authorization': `Bearer ${token}` },
      });

      const { transaction: base64Transaction } = response.data;
      const transaction = Transaction.from(Buffer.from(base64Transaction, 'base64'));
      const signedTransaction = await signTransaction(transaction);
      const serializedSignedTransaction = signedTransaction.serialize().toString('base64');

      await axios.post('https://backend.memebrewery.fun/api/sign-and-send', {
        signedTransaction: serializedSignedTransaction,
        tokenId: selectedToken.id,
        amount: sellAmount,
        phase: 'sell'
      }, {
        withCredentials: true,
        headers: { 'Authorization': `Bearer ${token}` },
      });

      setMessage('Sale successful!');
      setSnackbarOpen(true);
      setSelectedToken(null);
      setSellAmount(0);
      setEstimatedSolReceived(0);

      await fetchTokens();

    } catch (error) {
      setMessage('Sale failed: ' + (error.response?.data?.error || error.message));
      setSnackbarOpen(true);
    }
  };

  const handlePurchaseAmountChange = (e) => {
    const amountInSol = e.target.value;
    setPurchaseAmount(amountInSol);
    setBuyError(amountInSol === '' || isNaN(amountInSol) || parseFloat(amountInSol) <= 0);

    const parsedAmount = parseFloat(amountInSol);

    if (selectedToken && !isNaN(parsedAmount)) {
      const tokensReceived = calculateTokensReceived(parsedAmount, selectedToken.availableSupply, A, N);
      setEstimatedTokens(tokensReceived);
    } else {
      setEstimatedTokens(0);
    }
  };

  const handleSellAmountChange = (e) => {
    const amountToSell = e.target.value;
    setSellAmount(amountToSell);
    setSellError(amountToSell === '' || isNaN(amountToSell) || parseFloat(amountToSell) <= 0);

    const parseSellAmount = parseFloat(amountToSell);

    if (selectedToken && !isNaN(parseSellAmount)) {
      const estimatedSol = parseSellAmount * selectedToken.price;
      setEstimatedSolReceived(estimatedSol.toFixed(6));
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleHowItWorksClose = () => {
    setHowItWorksOpen(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const calculatePercentage = (amount, total) => (amount / total) * 100;

  const calculateActiveDuration = (createdAt) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const totalMinutesActive = Math.floor((currentDate - createdDate) / (1000 * 60));
    const hoursActive = Math.floor(totalMinutesActive / 60);
    const minutesActive = totalMinutesActive % 60;

    if (hoursActive < 24) {
      return `${hoursActive}h ${minutesActive}m`;
    } else {
      const daysActive = Math.floor(hoursActive / 24);
      const remainingHoursActive = hoursActive % 24;
      return `${daysActive}d ${remainingHoursActive}h`;
    }
  };

  const followToken = async (tokenId) => {
    try {
      const followerAddress = user.walletAddress; 
      await axios.post('https://backend.memebrewery.fun/api/follow/token', {
        followerAddress,
        tokenId,
      });
      alert('Token followed successfully');
    } catch (error) {
      console.error('Error following token:', error);
      alert('Error following token');
    }
  };

  const followWallet = async (walletAddress) => {
    try {
      const followerAddress = user.walletAddress; 
      await axios.post('https://backend.memebrewery.fun/api/follow/user', {
        followerAddress,
        tokenId: selectedToken.id,
      });
      alert('User followed successfully');
    } catch (error) {
      console.error('Error following user:', error);
      alert('Error following user');
    }
  };

  const chartData = {
    labels: historicalData.map((data) => data.date),
    datasets: [
      {
        label: selectedToken?.name,
        data: historicalData.map((data) => data.price),
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
        },
      },
      y: {
        beginAtZero: false,
      },
    },
  };

  const ChartComponent = ({ chartType, historicalData, ohlcChartData }) => {
    const baseChartData = {
      labels: historicalData.map((data) => data.date),
      datasets: [
        {
          label: selectedToken?.name,
          data: historicalData.map((data) => data.price),
          fill: false,
          borderColor: 'rgba(75,192,192,1)',
          backgroundColor: 'rgba(75,192,192,0.2)',
          tension: 0.1,
        },
      ],
    };

    switch (chartType) {
      case 'Line':
        return <Line data={baseChartData} options={chartOptions} />;
      case 'Bar':
        return <Bar data={baseChartData} options={chartOptions} />;
      case 'Doughnut':
        return <Doughnut data={baseChartData} options={chartOptions} />;
      case 'Pie':
        return <Pie data={baseChartData} options={chartOptions} />;
      case 'Radar':
        return <Radar data={baseChartData} options={chartOptions} />;
      case 'PolarArea':
        return <PolarArea data={baseChartData} options={chartOptions} />;
      case 'Bubble':
        return <Bubble data={baseChartData} options={chartOptions} />;
      case 'Scatter':
        return <Scatter data={baseChartData} options={chartOptions} />;
      case 'Candlestick':
        return <CandlestickChart data={ohlcChartData} options={chartOptions} />;
      default:
        return <Line data={baseChartData} options={chartOptions} />;
    }
  };

  const accumulatedSol = selectedToken?.accumulatedSol || 0;
  const availableTokens = selectedToken?.availableSupply || 0;
  const tokenSymbol = selectedToken?.symbol;
  const marketCap = (selectedToken?.price || 0) * (selectedToken?.supply || 0) * solPrice;
  const marketCapThreshold = 400 * solPrice;
  const progress = Math.min((accumulatedSol / 400) * 100, 100);

  const tokensPerPage = 100; 
  const paginatedTokens = tokens; // already handled by backend pagination

  return (
    <div className="relative flex size-full min-h-screen flex-col bg-[#111c22] overflow-x-hidden">
      {/* Header */}
      <header className="flex items-center justify-between whitespace-nowrap border-b border-solid border-b-[#233c48] px-10 py-3">
        <MarketplaceAppBar
          handleFilter={handleFilter}
          handleLaunchToken={() => navigate('/create-token')}
          handleSearchSubmit={handleSearchSubmit}
        />
      </header>

      <div className="gap-1 px-6 flex flex-1 py-5 w-full">
        <div className="layout-content-container flex flex-col w-full flex-1">
          {/* 4 cards per row: lg={3} */}
          <Grid container spacing={3} className="pt-4">
            {tokens.map((token) => (
              <Grid item key={token.id} xs={12} sm={6} md={4} lg={3}>
                <div
                  onClick={() => handleCardClick(token)}
                  className="cursor-pointer flex flex-col gap-2 rounded-xl border border-[#325567] p-3 transition-colors"
                  style={{
                    backgroundColor: '#424242'
                  }}
                >
                  <style jsx>{`
                    .hover-card:hover {
                      background-color: #515151;
                    }
                  `}</style>
                  <div className="hover-card flex flex-col flex-1 h-full">
                    <div className="flex justify-between items-start">
                      <Avatar
                        src={token.icon}
                        alt={`${token.name} icon`}
                        sx={{ width: 64, height: 64 }}
                      />
                      <div className="flex items-center space-x-1">
                        {token.website && (
                          <IconButton href={token.website} target="_blank" size="small">
                            <LanguageIcon style={{ color: 'white', fontSize: '1.2rem' }} />
                          </IconButton>
                        )}
                        {token.twitter && (
                          <IconButton href={token.twitter} target="_blank" size="small">
                            <TwitterIcon style={{ color: 'white', fontSize: '1.2rem' }} />
                          </IconButton>
                        )}
                        {token.telegram && (
                          <IconButton href={token.telegram} target="_blank" size="small">
                            <TelegramIcon style={{ color: 'white', fontSize: '1.2rem' }} />
                          </IconButton>
                        )}
                        <IconButton href={`https://solscan.io/address/${token.solanaMintAddress}?cluster=mainnet`} target="_blank" size="small">
                          <OpenInNewIcon style={{ color: 'white', fontSize: '1.2rem' }} />
                        </IconButton>
                      </div>
                    </div>
                    <div className="flex flex-col mt-2">
                      <Typography variant="h6" component="h2" className="text-white font-bold">
                        {token.name} ({token.symbol})
                      </Typography>
                      <Typography variant="body2" className="text-[#a0aec0] mt-1 line-clamp-2">
                        {token.description || 'No description available'}
                      </Typography>
                    </div>
                    <div className="flex flex-col text-sm pt-2 border-t border-[#2d3748] mt-2">
                      <div className="flex justify-between items-center mb-2">
                        <div className="flex items-center text-[#00ff00]">
                          <TrendingUpIcon style={{ marginRight: 5, fontSize: '1rem' }} />
                          <span className="font-bold">{parseFloat(token.liquiditySol).toFixed(5)} SOL ({calculatePercentage(parseFloat(token.liquiditySol), 400).toFixed(6)}%)</span>
                        </div>
                        <div className="flex items-center text-[#a0aec0]">
                          <AccountBalanceWalletIcon style={{ marginRight: 5, fontSize: '1rem' }} />
                          <span>{token.transactionCount || 0} txns</span>
                        </div>
                      </div>
                      <div className="flex justify-between items-center mb-2">
                        <div className="flex items-center text-[#a0aec0]">
                          <TrendingUpIcon style={{ marginRight: 5, fontSize: '1rem' }} />
                          <span>${parseFloat(token.marketVolume).toFixed(6) || 0}</span>
                        </div>
                        <div className="flex items-center text-[#a0aec0]">
                          <HourglassEmptyIcon style={{ marginRight: 5, fontSize: '1rem' }} />
                          <span>{calculateActiveDuration(token.createdAt) || 0}</span>
                        </div>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex items-center text-[#a0aec0]">
                          <MonetizationOnIcon style={{ marginRight: 5, fontSize: '1rem' }} />
                          <span>Mkt: {((token.supply || 0) * (token.price || 0) * (solPrice || DEFAULT_PRICE)).toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>

          {/* Pagination */}
          <div className="flex justify-center mt-6">
            <CustomPagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>

      {selectedToken && (
        <Dialog
          open={Boolean(selectedToken)}
          onClose={() => setSelectedToken(null)}
          fullWidth
          maxWidth="md"
          PaperProps={{
            style: {
              backgroundColor: '#111c22',
              color: '#fff',
            },
          }}
        >
          <DialogTitle className="text-white bg-[#111c22] border-b border-[#233c48]">
            Buy or Sell {selectedToken.name}
          </DialogTitle>
          <DialogContent className="bg-[#111c22] text-white">
            {/* Insert dialog content as before (Buy/Sell forms, charts, bonding curve, etc.) */}
            <TokenDetailsTabs tokenId={selectedToken.id} />
          </DialogContent>
          <DialogActions className="bg-[#111c22] border-t border-[#233c48]">
            <Button onClick={() => setSelectedToken(null)} sx={{ color: '#2badee' }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Dialog open={howItWorksOpen} onClose={handleHowItWorksClose} maxWidth="sm" fullWidth
        PaperProps={{
          style: {
            backgroundColor: '#111c22',
            color: '#fff',
          },
        }}
      >
        <DialogTitle className="text-white bg-[#111c22] border-b border-[#233c48]">How Meme Brewery Works</DialogTitle>
        <DialogContent className="bg-[#111c22] text-white">
          <Typography variant="body1" gutterBottom>
            Meme Brewery is the best place to discover (and launch) the next trending token!
          </Typography>
          <Typography variant="body2" gutterBottom><strong>Fair Launch</strong></Typography>
          <Typography variant="body2" gutterBottom>
            <ul className="list-disc list-inside">
              <li>No pre-sale, no insiders, max 1B supply</li>
              <li>Ownership renounced, immutable</li>
              <li>Fully audited smart contracts</li>
              <li>Buy and sell at any time</li>
            </ul>
          </Typography>
          <Typography variant="body2" gutterBottom><strong>When Brewery?</strong></Typography>
          <Typography variant="body2" gutterBottom>
            <ul className="list-disc list-inside">
              <li>Once market cap reaches 400 ($67K) SOL all remaining tokens and liquidity migrate to Raydium</li>
            </ul>
          </Typography>
          <Typography variant="body2" gutterBottom><strong>Launch your token on Meme Brewery</strong></Typography>
          <Typography variant="body2" gutterBottom>
            <ul className="list-disc list-inside">
              <li>Takes just a few seconds ✅</li>
              <li>Free Enhanced Token Info! ✅</li>
              <li>Only ~0.02 SOL to deploy ✅</li>
            </ul>
          </Typography>
          <Typography variant="body2" gutterBottom><strong>Disclaimer</strong></Typography>
          <Typography variant="body2" gutterBottom>
            Tokens launched on this platform are not endorsed by Meme Brewery. Meme Brewery is not an investment platform; your tokens could lose significant value at any time. By using Meme Brewery, you acknowledge all risks involved and agree to our Terms and Conditions.
          </Typography>
          <a href="/terms-and-conditions" className="text-[#2badee] hover:underline">Terms and Conditions</a>
        </DialogContent>
        <DialogActions className="bg-[#111c22] border-t border-[#233c48]">
          <Button onClick={handleHowItWorksClose} sx={{ color: '#2badee' }}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={message.startsWith('Purchase successful!') || message.startsWith('Sale successful!') ? 'success' : 'error'}>
          {message}
        </Alert>
      </Snackbar>

      <Ticker />
    </div>
  );
}

export default Marketplace;
