import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Card, CardContent, CardMedia, Box, Button, Avatar, Snackbar, Alert, IconButton, Tooltip } from '@mui/material';
import { ExpandLess, ExpandMore, PeopleAlt, PersonAddAlt } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';

function Profile() {
  const [profile, setProfile] = useState(null);
  const [tokenDetails, setTokenDetails] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [avatar, setAvatar] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [followers, setFollowers] = useState([]);
  const [followingTokens, setFollowingTokens] = useState([]);
  const [followingUsers, setFollowingUsers] = useState([]);
  const [showFollowers, setShowFollowers] = useState(false);
  const [showFollowing, setShowFollowing] = useState(false);
  const [isLoadingFollowers, setIsLoadingFollowers] = useState(false);
  const [isLoadingFollowing, setIsLoadingFollowing] = useState(false);

  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchProfileAndTransactions = async () => {
  //     try {
  //       const token = localStorage.getItem('auth-token');
  //       const profileResponse = await axios.get(`https://backend.memebrewery.fun/api/profile`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });

  //       setProfile(profileResponse.data);

  //       // Fetch following tokens and users
  //       const followingTokensResponse = await axios.get(`https://backend.memebrewery.fun/api/following/tokens`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       setFollowingTokens(followingTokensResponse.data);

  //       const followingUsersResponse = await axios.get(`https://backend.memebrewery.fun/api/following/users`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       setFollowingUsers(followingUsersResponse.data);

  //       // Fetch followers (users following the current user)
  //       const followersResponse = await axios.get(`https://backend.memebrewery.fun/api/followers/user/${profileResponse.data.walletAddress}`);
  //       setFollowers(followersResponse.data);


  //       let transactionsData = [];
  //       if (profileResponse.data.walletAddress) {
  //         const transactionsResponse = await axios.get(`https://backend.memebrewery.fun/api/transactions`, {
  //           params: { walletAddress: profileResponse.data.walletAddress },
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         });

  //         transactionsData = transactionsResponse.data.transactions || [];
  //         setTransactions(transactionsData);
  //       }

  //       if (profileResponse.data.tokens && profileResponse.data.tokens.length > 0) {
  //         const tokensResponse = await axios.get(`https://backend.memebrewery.fun/api/tokens/ids`, {
  //           params: { tokenIds: profileResponse.data.tokens.join(',') },
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         });

  //         const tokensWithAmounts = await Promise.all(
  //           tokensResponse.data.tokens.map(async (token) => {
  //             const amountOwned = calculateTokenAmount(token.id, transactionsData);

  //             // Check if there's a 'finalize-creation' transaction for this token
  //             const createdByUser = transactionsData.some(
  //               (tx) => tx.tokenId === token.id && tx.type === 'finalize-creation'
  //             );

  //             return { ...token, amountOwned, createdByUser };
  //             // return { ...token, amountOwned };
  //           })
  //         );

  //         setTokenDetails(tokensWithAmounts);
  //       }

  //       setIsLoading(false);
  //     } catch (error) {
  //       console.error('Error fetching profile or transactions:', error);
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchProfileAndTransactions();
  // }, []);

  // new code to fix the dependence on following another creator to show tokens owned and created 12/4/2024 DMD
  useEffect(() => {
    const fetchProfileAndTokens = async () => {
      try {
        const token = localStorage.getItem('auth-token');
        const profileResponse = await axios.get(`https://backend.memebrewery.fun/api/profile`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProfile(profileResponse.data);
    
        const transactionsResponse = await axios.get(`https://backend.memebrewery.fun/api/transactions`, {
          params: { walletAddress: profileResponse.data.walletAddress },
          headers: { Authorization: `Bearer ${token}` },
        });
        const transactionsData = transactionsResponse.data.transactions || [];
        setTransactions(transactionsData);
    
        // Fetch all tokens
        const tokensResponse = await axios.get(`https://backend.memebrewery.fun/api/tokens`, {
          headers: { Authorization: `Bearer ${token}` },
        });
    
        const tokensWithAmounts = await Promise.all(
          tokensResponse.data.tokens.map(async (token) => {
            const amountOwned = calculateTokenAmount(token.id, transactionsData);
            const createdByUser = transactionsData.some(
              (tx) => tx.tokenId === token.id && tx.type === 'finalize-creation'
            );
            return { ...token, amountOwned, createdByUser };
          })
        );
    
        // Filter tokens to include only those with non-zero balance or created by the user
        const userTokens = tokensWithAmounts.filter(token => token.amountOwned > 0 || token.createdByUser);
        setTokenDetails(userTokens);
    
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching profile or transactions:', error);
        setIsLoading(false);
      }
    };

    fetchProfileAndTokens();
  }, []);


  const fetchFollowers = async () => {
    try {
      const response = await axios.get(`https://backend.memebrewery.fun/api/followers/user/${profile.walletAddress}`);
      setFollowers(response.data);
    } catch (error) {
      console.error('Error fetching followers:', error);
    }
  };

  useEffect(() => {
    fetchFollowers();
  }, []);

  const fetchFollowersData = async () => {
    try {
      const token = localStorage.getItem('auth-token');
      const response = await axios.get(`https://backend.memebrewery.fun/api/followers/user/${profile.walletAddress}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFollowers(response.data);
    } catch (error) {
      console.error('Error fetching followers:', error);
      setMessage('Failed to fetch followers');
      setSnackbarOpen(true);
    }
  };
  
  const fetchFollowingData = async () => {
    try {
      const token = localStorage.getItem('auth-token');
      const followingUsersResponse = await axios.get(`https://backend.memebrewery.fun/api/following/users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFollowingUsers(followingUsersResponse.data);
  
      const followingTokensResponse = await axios.get(`https://backend.memebrewery.fun/api/following/tokens`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFollowingTokens(followingTokensResponse.data);
    } catch (error) {
      console.error('Error fetching following data:', error);
      setMessage('Failed to fetch following data');
      setSnackbarOpen(true);
    }
  };

  // const calculateTokenAmount = (tokenId, transactions) => {
  //   if (!Array.isArray(transactions) || transactions.length === 0) {
  //     console.log('No transactions available for calculation.');
  //     return 0;
  //   }

  //   const tokenTransactions = transactions.filter(tx => tx.tokenId === tokenId);
  //   console.log('Filtered token transactions:', tokenTransactions);

  //   const totalAmount = tokenTransactions.reduce((acc, tx) => {
  //     if (tx.type === 'buy' || tx.type === 'finalize-creation') {
  //       return acc + tx.amount;
  //     } else if (tx.type === 'sell') {
  //       return acc - tx.amount;
  //     }
  //     return acc;
  //   }, 0);

  //   return totalAmount;
  // };

  const calculateTokenAmount = (tokenId, transactions) => {
    if (!Array.isArray(transactions) || transactions.length === 0) {
      return 0;
    }
    const tokenTransactions = transactions.filter(tx => tx.tokenId === tokenId);
    return tokenTransactions.reduce((acc, tx) => {
      if (tx.type === 'buy' || tx.type === 'finalize-creation') {
        return acc + tx.amount;
      } else if (tx.type === 'sell') {
        return acc - tx.amount;
      }
      return acc;
    }, 0);
  };

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length) {
      setAvatar(acceptedFiles[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (avatar) {
      formData.append('avatar', avatar);
    }

    try {
      const token = localStorage.getItem('auth-token');
      const response = await axios.post('https://backend.memebrewery.fun/api/profile', formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      setProfile(response.data);
      setAvatar(null); // Reset the avatar after successful upload
      setMessage('Profile updated successfully!');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating profile:', error);
      setMessage('Profile update failed: ' + (error.response?.data?.error || error.message));
      setSnackbarOpen(true);
    }
  };

  const handleFollowersClick = async () => {
    setShowFollowers((prev) => !prev);
    if (!showFollowers && followers.length === 0) {
      setIsLoadingFollowers(true);
      await fetchFollowersData();
      setIsLoadingFollowers(false);
    }
  };
  
  const handleFollowingClick = async () => {
    setShowFollowing((prev) => !prev);
    if (!showFollowing && followingUsers.length === 0 && followingTokens.length === 0) {
      setIsLoadingFollowing(true);
      await fetchFollowingData();
      setIsLoadingFollowing(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/webp': ['.webp'],
      'image/svg+xml': ['.svg'],
    },
  });

  const handleTokenClick = (tokenId) => {
    navigate(`/token/${tokenId}`);
  };

  // Add navigation handler for user profiles
  const handleUserClick = (walletAddress) => {
    navigate(`/profile/${walletAddress}`);
  };


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      {/* Back button */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={() => navigate('/')} color="primary">
          <ArrowBackIcon />
        </IconButton>
      </Box>

      {/* Profile Avatar */}
      {profile && (
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Avatar
            src={profile.avatar}
            alt="User Avatar"
            sx={{
              width: 140,  // Increased avatar size
              height: 140, // Increased avatar size
              margin: '0 auto',
              boxShadow: 3,
              border: '4px solid',
              borderColor: 'primary.main',
            }}
          />

          {/* Avatar Update Form */}
          <form onSubmit={handleSubmit}>
            <Box
              {...getRootProps()}
              mt={1}
              mb={1}
              p={1}
              border="2px dashed grey"
              sx={{
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: 1,
                bgcolor: avatar ? 'primary.light' : 'background.default',
                color: avatar ? 'primary.contrastText' : 'text.secondary',
              }}
            >
              <input {...getInputProps()} />
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {avatar ? avatar.name : 'Drag and drop an avatar here, or click to select one'}
              </Typography>
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ fontSize: '0.875rem', fontWeight: 'bold', boxShadow: 1, borderRadius: 1 }}
            >
              Update Profile
            </Button>
          </form>

          {/* Icons under Avatar */}
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 2 }}>
            {/* Followers Icon */}
            {/* <Tooltip title={showFollowers ? 'Hide Followers' : 'Show Followers'}>
              <IconButton
                onClick={() => setShowFollowers((prev) => !prev)}
                color="primary"
                sx={{ boxShadow: 1, fontSize: 'small' }}
              >
                <PeopleAlt />
              </IconButton>
            </Tooltip> */}

            {/* Followers Icon */}
            <Tooltip title={showFollowers ? 'Hide Followers' : 'Show Followers'}>
              <IconButton onClick={handleFollowersClick} color="primary" sx={{ boxShadow: 1, fontSize: 'small' }}>
                <PeopleAlt />
              </IconButton>
            </Tooltip>

            {/* Following Icon */}
            {/* <Tooltip title={showFollowing ? 'Hide Following' : 'Show Following'}>
              <IconButton
                onClick={() => setShowFollowing((prev) => !prev)}
                color="primary"
                sx={{ boxShadow: 1, fontSize: 'small' }}
              >
                <PersonAddAlt />
              </IconButton>
            </Tooltip> */}

            {/* Following Icon */}
            <Tooltip title={showFollowing ? 'Hide Following' : 'Show Following'}>
              <IconButton onClick={handleFollowingClick} color="primary" sx={{ boxShadow: 1, fontSize: 'small' }}>
                <PersonAddAlt />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      )}

      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        <>
          <Grid container spacing={0.5} sx={{ mt: 2 }}>
            {tokenDetails.map((token) => (
              <Grid item xs={6} sm={4} md={2} key={token.id} sx={{ padding: 0.5 }}>
                <Card 
                      onClick={() => handleTokenClick(token.id)}
                      sx={{ 
                        boxShadow: 1, 
                        borderRadius: 2, 
                        p: 1, 
                        maxWidth: '100%',
                        cursor: 'pointer',
                        transition: 'transform 0.2s',
                        '&:hover': {
                          transform: 'scale(1.02)',
                          boxShadow: 3,
                        }
                      }}
                    >
                  <CardMedia
                    component="img"
                    alt={token.name}
                    height="120"
                    image={token.icon}
                    sx={{ objectFit: 'contain', width: '100%', margin: 'auto' }}
                  />
                  <CardContent sx={{ textAlign: 'center', p: 0.5 }}>
                    <Typography variant="h6" component="div" sx={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                      {token.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                      Amount Owned: {token.amountOwned}
                    </Typography>
                    {token.createdByUser && (
                      <Typography variant="body2" color="primary" sx={{ mt: 0.5, fontSize: '0.7rem' }}>
                        Created by you on {new Date(token.createdAt).toLocaleDateString()}
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {/* Followers Section (Compact Layout with 6 Cards per Row) */}
      {showFollowers && (
        <Box mt={2}>
          <Typography variant="h6" gutterBottom>Followers</Typography>
          {isLoadingFollowers ? (
            <Typography>Loading followers...</Typography>
          ) : followers.length > 0 ? (
            <Grid container spacing={0.5} sx={{ mt: 1 }}>
              {followers.map((follower) => (
                <Grid item xs={6} sm={4} md={2} key={follower?.followerAddress || 'unknown'} sx={{ padding: 0.5 }}>
                  <Card sx={{ display: 'flex', alignItems: 'center', p: 1, borderRadius: 2, boxShadow: 1, maxWidth: '100%' }}>
                    <Avatar alt={follower?.followerAddress || 'Unknown'} src={`https://avatars.dicebear.com/api/identicon/${follower?.followerAddress || 'unknown'}.svg`} sx={{ mr: 2, width: 50, height: 50 }} />
                    <CardContent sx={{ p: 0.5 }}>
                      <Typography variant="body2" fontWeight="bold" sx={{ fontSize: '0.875rem' }}>
                        {follower?.followerAddress ? `${follower.followerAddress.slice(0, 6)}...${follower.followerAddress.slice(-4)}` : 'Unknown'}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body2" mt={1}>No followers yet.</Typography>
          )}
        </Box>
      )}

      {/* Following Section (Compact Layout with 6 Cards per Row) */}
      {showFollowing && (
        <Box mt={2}>
          <Typography variant="h6" gutterBottom>
            Following
          </Typography>
          {followingTokens.length > 0 ? (
            <Box mt={1}>
              <Typography variant="subtitle1" fontWeight="bold" sx={{ fontSize: '0.875rem' }}>
                Tokens
              </Typography>
              <Grid container spacing={0.5} sx={{ mt: 1 }}>
                {followingTokens.map((token) => (
                  <Grid item xs={6} sm={4} md={2} key={token?.id || 'unknown'} sx={{ padding: 0.5 }}>
                     <Card 
                        onClick={() => handleTokenClick(token.id)}
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          p: 1, 
                          borderRadius: 2, 
                          boxShadow: 1, 
                          maxWidth: '100%',
                          cursor: 'pointer',
                          transition: 'transform 0.2s',
                          '&:hover': {
                            transform: 'scale(1.02)',
                            boxShadow: 3,
                          }
                        }}
                      >
                      <Avatar
                        alt={token?.name || 'Unknown'}
                        src={`https://avatars.dicebear.com/api/identicon/${token?.icon || 'unknown'}.webp`}
                        sx={{ mr: 2, width: 50, height: 50 }} // Larger avatar size
                      />
                      <CardContent sx={{ p: 0.5 }}>
                        <Typography variant="body2" fontWeight="bold" sx={{ fontSize: '0.875rem' }}>
                          {token?.name || 'Unknown'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                          Token ID: {token?.id || 'Unknown'}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <Typography variant="body2" mt={1}>
              Not following any tokens yet.
            </Typography>
          )}

          {followingUsers.length > 0 ? (
            <Box mt={2}>
              <Typography variant="subtitle1" fontWeight="bold" sx={{ fontSize: '0.875rem' }}>
                Users
              </Typography>
              <Grid container spacing={0.5} sx={{ mt: 1 }}>
                {followingUsers.map((user) => (
                  <Grid item xs={6} sm={4} md={2} key={user?.followedAddress || 'unknown'} sx={{ padding: 0.5 }}>
                    <Card 
                        // onClick={() => handleUserClick(user.followedAddress)}
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          p: 1,
                          borderRadius: 2, 
                          boxShadow: 1, 
                          maxWidth: '100%',
                          cursor: 'pointer',
                          transition: 'transform 0.2s',
                          '&:hover': {
                            transform: 'scale(1.02)',
                            boxShadow: 3,
                          }
                        }}
                      >
                      <Avatar
                        alt={user?.followedAddress || 'Unknown'}
                        src={`https://avatars.dicebear.com/api/identicon/${user?.followedAddress || 'unknown'}.svg`}
                        sx={{ mr: 2, width: 50, height: 50 }} // Larger avatar size
                      />
                      <CardContent sx={{ p: 0.5 }}>
                        <Typography variant="body2" fontWeight="bold" sx={{ fontSize: '0.875rem' }}>
                          {user?.followedAddress
                            ? `${user.followedAddress.slice(0, 6)}...${user.followedAddress.slice(-4)}`
                            : 'Unknown'}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <Typography variant="body2" mt={1}>
              Not following any users yet.
            </Typography>
          )}
        </Box>
      )}

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={message.startsWith('Profile updated successfully!') ? 'success' : 'error'}
          variant="filled"
          elevation={6}
          sx={{
            width: '100%',
            borderRadius: 2,
            '& .MuiAlert-icon': {
              fontSize: '1.5rem',
            },
            '& .MuiAlert-message': {
              fontSize: '1rem',
              fontWeight: 500,
            },
          }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnackbarOpen(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Profile;
