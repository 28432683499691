import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { WalletContextProvider } from './context/WalletContext';
import { AuthProvider } from './context/AuthContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import './index.css';

const queryClient = new QueryClient();
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <WalletContextProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </WalletContextProvider>
    </React.StrictMode>
  </QueryClientProvider>
);
