// utils/solPriceCache.js
import axios from 'axios';

const SOL_PRICE_CACHE_KEY = 'solPriceCache';
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const DEFAULT_PRICE = 180.00;

export const fetchAndCacheSolPrice = async () => {
  const cachedData = localStorage.getItem(SOL_PRICE_CACHE_KEY);
  
  if (cachedData) {
    const { price, timestamp } = JSON.parse(cachedData);
    if (Date.now() - timestamp < CACHE_DURATION) {
      // Return cached price as a number, or default price if it's invalid
      return parseFloat(price) > 0 ? parseFloat(price) : DEFAULT_PRICE;
    }
  }

  try {
    const response = await axios.get('https://backend.memebrewery.fun/api/sol-price', { withCredentials: true });
    let newPrice = parseFloat(response.data.solPrice);

    // If the fetched price is invalid, use the default price
    if (isNaN(newPrice) || newPrice <= 0) {
      newPrice = DEFAULT_PRICE;
    }

    // Store the price in cache
    localStorage.setItem(SOL_PRICE_CACHE_KEY, JSON.stringify({
      price: newPrice,
      timestamp: Date.now()
    }));

    return newPrice;
  } catch (error) {
    console.error('Error fetching SOL price:', error);
    return DEFAULT_PRICE;
  }
};