import React, { useState } from 'react';
import { AppBar, Toolbar, Box, Button, TextField, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions, Typography, useMediaQuery, useTheme, IconButton } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import StarIcon from '@mui/icons-material/Star';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import Notifications from './Notifications';

const FilterButton = ({ children, onClick, icon: Icon }) => (
  <Button
    onClick={onClick}
    startIcon={<Icon />}
    sx={{
      backgroundColor: '#233c48',
      color: '#ffffff',
      '&:hover': { backgroundColor: '#325567' },
      fontSize: { xs: '0.75rem', sm: '0.875rem' },
      padding: { xs: '6px 8px', sm: '8px 12px' },
      marginRight: '5px',
      borderRadius: '8px',
    }}
  >
    {children}
  </Button>
);

const ActionButton = ({ children, onClick, color }) => (
  <Button
    onClick={onClick}
    sx={{
      backgroundColor: color,
      color: '#111c22',
      '&:hover': { backgroundColor: color, opacity: 0.9 },
      fontSize: { xs: '0.75rem', sm: '0.875rem' },
      padding: { xs: '6px 8px', sm: '8px 12px' },
      marginRight: '10px',
      borderRadius: '8px',
      fontWeight: 'bold',
    }}
  >
    {children}
  </Button>
);

export default function MarketplaceAppBar({ handleFilter, handleLaunchToken, handleSearchSubmit }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [howItWorksOpen, setHowItWorksOpen] = useState(false);

  const handleHowItWorksOpen = () => setHowItWorksOpen(true);
  const handleHowItWorksClose = () => setHowItWorksOpen(false);

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: '#111c22' }}>
        <Toolbar sx={{
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          padding: '10px 20px',
        }}>
          <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: isMobile ? 'center' : 'flex-start',
            gap: 1,
            mb: isMobile ? 2 : 0,
          }}>
            <FilterButton onClick={() => handleFilter('new')} icon={NewReleasesIcon}>New</FilterButton>
            <FilterButton onClick={() => handleFilter('trending')} icon={TrendingUpIcon}>Trending</FilterButton>
            <FilterButton onClick={() => handleFilter('rising')} icon={ShowChartIcon}>Rising</FilterButton>
            <FilterButton onClick={() => handleFilter('top')} icon={StarIcon}>Top</FilterButton>
            <FilterButton onClick={() => handleFilter('final')} icon={CheckCircleIcon}>Final</FilterButton>
          </Box>

          <Box component="form" onSubmit={handleSearchSubmit} sx={{
            display: 'flex',
            width: isMobile ? '100%' : '300px',
            mb: isMobile ? 2 : 0,
          }}>
            <TextField
              name="search"
              variant="outlined"
              size="small"
              placeholder="Search tokens..."
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#233c48',
                  '& fieldset': { borderColor: '#325567' },
                  '&:hover fieldset': { borderColor: '#2badee' },
                  '&.Mui-focused fieldset': { borderColor: '#2badee' },
                },
                '& input': { color: '#ffffff' },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#ffffff' }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button type="submit" sx={{
              backgroundColor: '#2badee',
              color: '#111c22',
              '&:hover': { backgroundColor: '#2badee', opacity: 0.9 },
              ml: 1,
            }}>
              <SearchIcon />
            </Button>
          </Box>

          <Box sx={{
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'flex-end',
            alignItems: 'center',
            width: isMobile ? '100%' : 'auto',
            gap: 1,
          }}>
            <ActionButton onClick={handleHowItWorksOpen} color="#2badee">How this works</ActionButton>
            <ActionButton onClick={handleLaunchToken} color="#00bfa5">Launch your Token!</ActionButton>
            <IconButton sx={{ color: '#ffffff' }}>
              <Notifications />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Dialog open={howItWorksOpen} onClose={handleHowItWorksClose} maxWidth="sm" fullWidth
        PaperProps={{
          style: {
            backgroundColor: '#111c22',
            color: '#fff',
          },
        }}
      >
        <DialogTitle className="text-white bg-[#111c22] border-b border-[#233c48]">How Meme Brewery Works</DialogTitle>
        <DialogContent className="bg-[#111c22] text-white">
          <Typography variant="body1" gutterBottom>
            Meme Brewery is the best place to discover (and launch) the next trending token!
          </Typography>
          <Typography variant="body2" gutterBottom><strong>Fair Launch</strong></Typography>
          <Typography variant="body2" gutterBottom>
            <ul className="list-disc list-inside">
              <li>No pre-sale, no insiders, max 1B supply</li>
              <li>Ownership renounced, immutable</li>
              <li>Fully audited smart contracts</li>
              <li>Buy and sell at any time</li>
            </ul>
          </Typography>
          <Typography variant="body2" gutterBottom><strong>When Brewery?</strong></Typography>
          <Typography variant="body2" gutterBottom>
            <ul className="list-disc list-inside">
              <li>Once market cap reaches 400 ($67K) SOL all remaining tokens and liquidity migrate to Raydium</li>
            </ul>
          </Typography>
          <Typography variant="body2" gutterBottom><strong>Launch your token on Meme Brewery</strong></Typography>
          <Typography variant="body2" gutterBottom>
            <ul className="list-disc list-inside">
              <li>Takes just a few seconds ✅</li>
              <li>Free Enhanced Token Info! ✅</li>
              <li>Only ~0.02 SOL to deploy ✅</li>
            </ul>
          </Typography>
          <Typography variant="body2" gutterBottom><strong>Disclaimer</strong></Typography>
          <Typography variant="body2" gutterBottom>
            Tokens launched on this platform are not endorsed by Meme Brewery. Meme Brewery is not an investment platform; your tokens could lose significant value at any time. By using Meme Brewery, you acknowledge all risks involved and agree to our Terms and Conditions.
          </Typography>
          <a href="/terms-and-conditions" className="text-[#2badee] hover:underline">Terms and Conditions</a>
        </DialogContent>
        <DialogActions className="bg-[#111c22] border-t border-[#233c48]">
          <Button onClick={handleHowItWorksClose} sx={{ color: '#2badee' }}>Close</Button>
        </DialogActions>
      </Dialog>

    </>
  );
}
