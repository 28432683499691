import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import { CandlestickController, CandlestickElement } from 'chartjs-chart-financial';
import 'chartjs-adapter-date-fns'; // Adapter for time-based x-axis

// Register CandlestickController and CandlestickElement
Chart.register(CandlestickController, CandlestickElement);

const CandlestickChart = ({ data, options }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const chartInstance = new Chart(ctx, {
      type: 'candlestick', // Ensure candlestick type is used
      data,
      options: {
        ...options,
        plugins: {
          legend: {
            display: false, // You can show/hide the legend as needed
          },
        },
        elements: {
          candlestick: {
            color: {
              up: '#4CAF50', // Green for up candles (close > open)
              down: '#F44336', // Red for down candles (close < open)
              unchanged: '#2196F3', // Blue for unchanged (optional)
              wickColor: '#555555', // Gray/Black for wick (neutral)
            },
          },
        },
      },
    });

    return () => {
      chartInstance.destroy(); // Clean up chart instance on unmount
    };
  }, [data, options]);

  return <canvas ref={chartRef} />;
};

export default CandlestickChart;
