import React, { useState, useEffect } from "react";
import ApexCharts from "react-apexcharts";
import io from "socket.io-client";

const CandlestickChartWithWebSocket = React.memo(({ tokenId, historicalData }) => {
  const [chartData, setChartData] = useState([]);
  const [socket, setSocket] = useState(null);

  // Initialize WebSocket connection and chart data
  useEffect(() => {
    const socketInstance = io("https://backend.memebrewery.fun");
    setSocket(socketInstance);

    setChartData(
      historicalData.map((item) => ({
        x: new Date(item.t),
        y: [item.o, item.h, item.l, item.c],
      }))
    );

    socketInstance.on("price-update", (update) => {
      if (update.tokenId === tokenId) {
        setChartData((prevData) => {
          const lastCandle = prevData[prevData.length - 1];
          const currentTime = new Date().getTime();

          if (currentTime - lastCandle.x.getTime() < 60000) {
            return [
              ...prevData.slice(0, -1),
              {
                ...lastCandle,
                y: [
                  lastCandle.y[0],
                  Math.max(lastCandle.y[1], update.price),
                  Math.min(lastCandle.y[2], update.price),
                  update.price,
                ],
              },
            ];
          } else {
            return [
              ...prevData,
              {
                x: new Date(),
                y: [update.price, update.price, update.price, update.price],
              },
            ];
          }
        });
      }
    });

    return () => {
      socketInstance.disconnect();
    };
  }, [tokenId, historicalData]);

  const chartOptions = {
    chart: {
      type: 'candlestick',
      height: 500, // Increased height
      width: '100%', // Set width to 100% of container
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
      },
      background: '#131722',
    },
    plotOptions: {
      candlestick: {
        colors: {
          upward: '#26a69a',
          downward: '#ef5350'
        },
        wick: {
          useFillColor: false,
        }
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          colors: '#787878'
        }
      }
    },
    yaxis: {
      tooltip: {
        enabled: true
      },
      labels: {
        style: {
          colors: '#787878'
        }
      }
    },
    grid: {
      borderColor: '#2a2e39'
    },
    tooltip: {
      theme: 'dark'
    },
    crosshairs: {
      show: true,
      position: 'both',
      opacity: 0.9,
      stroke: {
        color: '#b6b6b6',
        width: 1,
        dashArray: [3]
      }
    },
    toolbar: {
      show: true,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true
      }
    }
  };

  return (
    <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
      <ApexCharts
        options={chartOptions}
        series={[{ data: chartData }]}
        type="candlestick"
        height={500}
        width="100%"
      />
    </div>
  );
});

export default CandlestickChartWithWebSocket;
