import React, { useState, useEffect, useCallback } from 'react';
import { Box, TextField, Button, Typography, Paper, IconButton, Tooltip } from '@mui/material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ReplyIcon from '@mui/icons-material/Reply';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { parseISO, format } from 'date-fns';

const getRandomColor = () => {
  const colors = ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#FFC733'];
  return colors[Math.floor(Math.random() * colors.length)];
};

const StyledPartialAddress = styled(Typography)(({ color }) => ({
  fontWeight: 'bold',
  color,
  display: 'inline',
}));

function CommentsSection({ tokenId, user }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [reply, setReply] = useState({});
  const [likeCounts, setLikeCounts] = useState({});

  const fetchComments = useCallback(async () => {
    if (!tokenId) {
      console.error("Token ID is missing!");
      return;
    }
    try {
      const response = await axios.get(`https://backend.memebrewery.fun/api/pgcomments/${tokenId}?includeUser=true`);
      const fetchedComments = Array.isArray(response.data) ? response.data : [];
      const initialLikes = {};
      fetchedComments.forEach((comment) => {
        initialLikes[comment.id] = comment.likes || 0;
      });
      setComments(fetchedComments);
      setLikeCounts(initialLikes);
    } catch (error) {
      console.error('Error fetching comments:', error.message || error);
      setComments([]);
    }
  }, [tokenId]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  const handleAddComment = async () => {
    if (!newComment.trim()) {
      alert("Comment cannot be empty!");
      return;
    }
    if (!tokenId || !user?.id) {
      console.error("Missing required fields: tokenId or user ID");
      return;
    }
    try {
      await axios.post('https://backend.memebrewery.fun/api/pgcomments/', {
        tokenId,
        commentText: newComment,
        userId: user.id,
      });
      setNewComment('');
      await fetchComments();
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleLikeComment = async (commentId) => {
    try {
      await axios.post(`https://backend.memebrewery.fun/api/pgcomments/${commentId}/like`, {
        userId: user.id,
      });
      await fetchComments();
    } catch (error) {
      console.error('Error liking comment:', error);
    }
  };

  const handleReply = (commentId) => {
    setReply((prev) => ({ ...prev, [commentId]: '' }));
  };

  const handleAddReply = async (commentId) => {
    const replyText = reply[commentId];
    if (replyText.trim()) {
      try {
        await axios.post('https://backend.memebrewery.fun/api/pgcomments/reply', {
          commentId,
          replyText,
          tokenId,
          userId: user.id,
        });
        setReply((prev) => ({ ...prev, [commentId]: '' }));
        await fetchComments();
      } catch (error) {
        console.error('Error adding reply:', error);
      }
    }
  };

  const createNestedComments = (comments) => {
    const commentMap = new Map();
    comments.forEach((comment) => {
      commentMap.set(comment.id, { ...comment, replies: [] });
    });
    const nestedComments = [];
    comments.forEach((comment) => {
      if (comment.parent_comment_id === null) {
        nestedComments.push(commentMap.get(comment.id));
      } else {
        const parentComment = commentMap.get(comment.parent_comment_id);
        if (parentComment) {
          parentComment.replies.push(commentMap.get(comment.id));
        }
      }
    });
    return nestedComments;
  };

  const nestedComments = createNestedComments(comments);

  const renderComments = (comment) => (
    <Paper key={comment.id} style={{ padding: '10px', marginBottom: '10px' }}>
      <Box display="flex" justifyContent="space-between">
        <Box>
        <StyledPartialAddress color={getRandomColor()}>
          {comment.userId && comment.walletAddress
            ? `${comment.walletAddress.slice(0, 4)}...${comment.walletAddress.slice(-4)}`
            : 'Unknown Address'}
        </StyledPartialAddress>
          <Typography variant="body2" color="textSecondary" sx={{ ml: 1, display: 'inline' }}>
            {comment.createdAt
              ? format(parseISO(comment.createdAt), 'MMM d, yyyy HH:mm')
              : 'Unknown Date'}
          </Typography>
        </Box>
        <Box>
          <Tooltip title="Like">
            <IconButton onClick={() => handleLikeComment(comment.id)} size="small" color="primary">
              <ThumbUpAltIcon />
            </IconButton>
          </Tooltip>
          <Typography variant="caption" sx={{ ml: 0.5 }}>
            {likeCounts[comment.id] || 0}
          </Typography>
          <Tooltip title="Reply">
            <IconButton onClick={() => handleReply(comment.id)} size="small" color="secondary">
              <ReplyIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Typography variant="body1" sx={{ mt: 1 }}>
        {comment.commentText}
      </Typography>
      {comment.replies && comment.replies.length > 0 && (
        <Box mt={2} pl={4}>
          {comment.replies.map(renderComments)}
        </Box>
      )}
      {reply[comment.id] !== undefined && (
        <Box mt={2}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Write a reply..."
            value={reply[comment.id]}
            onChange={(e) =>
              setReply((prev) => ({ ...prev, [comment.id]: e.target.value }))
            }
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleAddReply(comment.id)}
            sx={{ mt: 1 }}
          >
            Reply
          </Button>
        </Box>
      )}
    </Paper>
  );

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Comments
      </Typography>
      <TextField
        fullWidth
        multiline
        rows={3}
        variant="outlined"
        placeholder="Write a comment..."
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
      />
      <Button variant="contained" color="primary" onClick={handleAddComment} sx={{ mt: 2 }}>
        Post Comment
      </Button>
      <Box mt={4}>
        {nestedComments.length > 0 ? (
          nestedComments.map(renderComments)
        ) : (
          <Typography variant="body2" color="textSecondary">
            No comments yet. Be the first to comment!
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default CommentsSection;