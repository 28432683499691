import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { AuthContext, authenticateUser } from '../context/AuthContext';
import { WalletContext } from '../context/WalletContext';
import { Container, Typography, TextField, Button, Card, CardContent, Box, Snackbar, Alert, InputAdornment, Modal } from '@mui/material';
import { ReactComponent as SolanaLogo } from '../assets/solana-logo.svg';
import { Transaction as SolanaTransaction } from '@solana/web3.js';
import ProgressBar from './ProgressBar';
import { calculateTokensReceived } from './bondingCurve';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useWallet } from '@solana/wallet-adapter-react';
import { fetchAndCacheSolPrice } from '../utils/solPriceCache';
import { CircularProgress } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew'; // Make sure to import the icon

function CreateToken() {
  const { user, authenticateUser } = useContext(AuthContext);  // <-- This should work
  const { address, signTransaction } = useContext(WalletContext);
  const [tokenName, setTokenName] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [supply, setSupply] = useState(1_000_000_000);
  const [description, setDescription] = useState('');
  const [initialPurchaseAmount, setInitialPurchaseAmount] = useState(0);
  const [estimatedTokens, setEstimatedTokens] = useState(0);
  const [price, setPrice] = useState('');
  const [website, setWebsite] = useState('');
  const [twitter, setTwitter] = useState('');
  const [telegram, setTelegram] = useState('');
  const [icon, setIcon] = useState(null);
  const [banner, setBanner] = useState(null);
  const [message, setMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [calculatorOpen, setCalculatorOpen] = useState(false);
  const [calculatedPrice, setCalculatedPrice] = useState('');
  const [solPrice, setSolPrice] = useState(0);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [newTokenId, setNewTokenId] = useState(null);
  const [amount, setAmount] = useState(0);
  const { connected, publicKey } = useWallet();
  const [createdToken, setCreatedToken] = useState(null); // State to store created token data
  const navigate = useNavigate();
  const A = 75;    // exponent for bonding curve
  const N = 50000;  // exponent for bonding curve

  // useEffect(() => {
  //   if (!connected) {
  //     navigate('/'); // Redirect to home if wallet is not connected
  //   } else if (!user && publicKey) {
  //     authenticateUser(publicKey.toString());
  //   }
  // }, [connected, user, publicKey, authenticateUser, navigate]);


  useEffect(() => {
    const getSolPrice = async () => {
      try {
        const price = await fetchAndCacheSolPrice();
        setSolPrice(price);
      } catch (error) {
        console.error('Error getting SOL price:', error);
      }
    };

    getSolPrice();
  }, []);

  const notifyFollowers = async (walletAddress, message) => {
    try {
      await fetch('https://backend.memebrewery.fun/api/notifications/notify-followers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ walletAddress, message }),
      });
    } catch (error) {
      console.error('Error notifying followers:', error);
    }
  };

  const calculateEstimatedTokens = (amountInSol, a, n) => {
    const currentSupply = supply; // Current supply of tokens
    const estimatedTokens = Math.pow(amountInSol / a + Math.pow(792381571, n + 1) / (n + 1), 1 / (n + 1)) - 792381571;
    return estimatedTokens;
  };

  const handleInitialPurchaseAmountChange = (e) => {
    const amountInSol = parseFloat(e.target.value);
    setInitialPurchaseAmount(amountInSol);

    if (!isNaN(amountInSol) && amountInSol > 0) {
      const estimatedTokens = calculateTokensReceived(amountInSol, 792381571, A, N); // Replace with the correct A and N values
      setEstimatedTokens(estimatedTokens.toFixed(2)); // Display the estimated tokens with 2 decimal places
    } else {
      setEstimatedTokens(0);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setProgress(0);

    if (!address) {
      setMessage('Please log in and connect your wallet to create a token.');
      setSnackbarOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append('name', tokenName);
    formData.append('symbol', tokenSymbol);
    formData.append('supply', supply);
    formData.append('description', description);
    formData.append('initialPurchaseAmount', initialPurchaseAmount.toString());
    formData.append('price', price);
    formData.append('website', website);
    formData.append('twitter', twitter);
    formData.append('telegram', telegram);
    if (icon) formData.append('icon', icon);
    if (banner) formData.append('banner', banner);

    try {
      const token = localStorage.getItem('auth-token');
      setMessage('Step 1: Prepare the purchase transaction...');
      setSnackbarOpen(true);
    
      const feeResponse = await axios.post('https://backend.memebrewery.fun/api/tokens/initial-purchase', {
        initialPurchaseAmount,
      }, {
        withCredentials: true,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    
      setMessage('Step 2: Signing the transaction...');
      setProgress(40);
    
      const { transaction: feeTransaction, amount } = feeResponse.data;
      const feeTrans = SolanaTransaction.from(Buffer.from(feeTransaction, 'base64'));
      const signedTransaction = await signTransaction(feeTrans);
    
      const serializedSignedTransaction = signedTransaction.serialize().toString('base64');
    
      setMessage('Step 3: Confirming the payment...');
      setProgress(60);
    
      const confirmResponse = await axios.post('https://backend.memebrewery.fun/api/sign-and-send', {
        signedTransaction: serializedSignedTransaction,
        phase: 'initial-purchase',
        amount: initialPurchaseAmount,
      }, {
        withCredentials: true,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    
      const { nextStep } = confirmResponse.data;
    
      setMessage('Step 4: Creating the token after payment confirmation...');
      setProgress(80);
    
      // Proceed with the next step to create the token in the backend
      const createTokenResponse = await axios.post(nextStep, formData, {
        withCredentials: true,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      const { transaction, tokenId, solanaMintAddress } = createTokenResponse.data;

      setCreatedToken({
        ...createTokenResponse.data,
        solanaMintAddress 
      });
      
      setMessage('Step 5: Finalizing the token creation...');
      setProgress(90);

      // Call sign-and-send to finalize the token creation
      await axios.post('https://backend.memebrewery.fun/api/sign-and-send', {
        signedTransaction: transaction,
        phase: 'finalize-creation',
        tokenId,
        amount: initialPurchaseAmount,
      }, {
        withCredentials: true,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      // Display success message for 5 seconds, then prompt to go back
      setTimeout(() => {
        setMessage('Token created successfully! Please use the back button at the top of the screen to return to the main page.');
        setSnackbarOpen(true);
      }, 5000);

      setCreatedToken({ ...createTokenResponse.data, solanaMintAddress });

      await notifyFollowers(address, `A new token has been created by ${address}`);

      // setMessage('Token created successfully!');
      setProgress(100);
      setLoading(false);

    
    } catch (error) {
      if (error.message.includes('User canceled the transaction')) {
        setMessage('Token creation was canceled by the user.');
      } else {
        setMessage('Token creation failed: ' + (error.response?.data?.error || error.message));
      }
      setSnackbarOpen(true);
      setLoading(false);
    }
  }

  const handleDrop = (acceptedFiles, setFile) => {
    if (acceptedFiles.length) {
      setFile(acceptedFiles[0]);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleOpenCalculator = () => {
    setCalculatorOpen(true);
  };

  const handleCloseCalculator = () => {
    setCalculatorOpen(false);
  };

  const handleCalculatePrice = () => {
    const calculated = solPrice / supply;
    setCalculatedPrice(calculated.toFixed(10));
  };

  const applyCalculatedPrice = () => {
    setPrice(calculatedPrice);
    setCalculatorOpen(false);
  };

  const { getRootProps: getIconRootProps, getInputProps: getIconInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, setIcon)
  });

  const { getRootProps: getBannerRootProps, getInputProps: getBannerInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, setBanner)
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      {/* Back Button */}
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', mt: 2, ml: 2 }}>
        <Button 
          onClick={() => navigate('/')} 
          variant="contained" 
          color="primary" 
          startIcon={<ArrowBackIcon />} 
          sx={{ fontWeight: 'bold' }}
        >
          Back to Home
        </Button>
      </Box>

      <Container maxWidth="sm">
        <Box mt={5}>
          <Card>
            <CardContent>
              <Typography variant="h4" component="h1" gutterBottom>
                Create a New Token
              </Typography>
              <Typography color="textSecondary">
                A fee of 0.02 SOL will be charged for creating a token.
              </Typography>
              {loading && (
                <>
                  <ProgressBar progress={progress} />
                  <Typography color="textSecondary" gutterBottom>
                    This process will take several steps to complete. Please be patient.
                  </Typography>
                </>
              )}
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Token Name"
                  value={tokenName}
                  onChange={(e) => setTokenName(e.target.value)}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Token Symbol"
                  value={tokenSymbol}
                  onChange={(e) => setTokenSymbol(e.target.value)}
                  fullWidth
                  margin="normal"
                  required
                  inputProps={{ maxLength: 10 }} // Ensuring the max length is 10 characters
                />
                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                />
                <TextField
                  label="Be the first person to buy your token (Amount in SOL)"
                  type="number"
                  value={initialPurchaseAmount}
                  onChange={handleInitialPurchaseAmountChange}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SolanaLogo width={24} height={24} />
                      </InputAdornment>
                    ),
                    inputProps: {
                      step: "0.001", // Allows both whole numbers and fractional values
                      min: "0.0001",     // Ensures no negative values can be entered
                    },
                  }}
                />
                {initialPurchaseAmount > 0 && (
                  <Typography color="textSecondary">
                    Estimated Tokens: {estimatedTokens}
                  </Typography>
                )}
                <TextField
                  label="Website (Optional)"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Twitter (Optional)"
                  value={twitter}
                  onChange={(e) => setTwitter(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Telegram (Optional)"
                  value={telegram}
                  onChange={(e) => setTelegram(e.target.value)}
                  fullWidth
                  margin="normal"
                />

                <Box {...getIconRootProps()} mt={2} mb={2} p={2} border="1px dashed grey">
                  <input {...getIconInputProps()} />
                  <Typography variant="h6">Icon</Typography>
                  <Typography>Drag and drop an icon here, or click to select one</Typography>
                  {icon && <Typography>{icon.name}</Typography>}
                </Box>

                <Box {...getBannerRootProps()} mt={2} mb={2} p={2} border="1px dashed grey">
                  <input {...getBannerInputProps()} />
                  <Typography variant="h6">Banner</Typography>
                  <Typography>Drag and drop a banner here, or click to select one</Typography>
                  {banner && <Typography>{banner.name}</Typography>}
                </Box>

                <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
                  {/* {loading ? 'Creating Token...' : 'Create Token'} */}
                  {loading ? <CircularProgress size={24} /> : 'Create Token'}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Box>
      
      </Container>
      
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={message.startsWith('Token created successfully!') ? 'success' : 'error'}>
          {message}
        </Alert>
      </Snackbar>

      {createdToken && (
        <Modal open={!!createdToken} onClose={() => setCreatedToken(null)}>
          <Box
            sx={{
              p: 4,
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 24,
              maxWidth: 400,
              mx: 'auto',
              textAlign: 'center',
            }}
          >
            <Typography variant="h5" component="h2" gutterBottom>
              🎉 Token Created Successfully!
            </Typography>
            <Typography variant="body1" sx={{ mt: 2, fontWeight: 'bold' }}>
              {createdToken.name} ({createdToken.symbol})
            </Typography>
            <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
              You can now view your token on the Solana Explorer.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              href={`https://solscan.io/address/${createdToken.solanaMintAddress}?cluster=mainnet`}
              target="_blank"
              sx={{ mt: 3 }}
              startIcon={<OpenInNewIcon />}
            >
              View on Explorer
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setCreatedToken(null)}
              sx={{ mt: 2 }}
            >
              Close
            </Button>
          </Box>
        </Modal>
      )}

      <Modal open={calculatorOpen} onClose={handleCloseCalculator}>
        <Box
          position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)"
          width={400} bgcolor="background.paper" borderRadius="8px" boxShadow={24} p={4}
        >
          <Typography variant="h6" component="h2">
            Price Calculator
          </Typography>
          <TextField
            label="Supply"
            type="number"
            value={supply}
            fullWidth
            margin="normal"
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Calculated Price"
            type="number"
            value={calculatedPrice}
            fullWidth
            margin="normal"
            InputProps={{ readOnly: true }}
          />
          <Button variant="contained" color="primary" fullWidth onClick={handleCalculatePrice}>
            Calculate
          </Button>
          <Button variant="contained" color="secondary" fullWidth onClick={applyCalculatedPrice} style={{ marginTop: '16px' }}>
            Use Calculated Price
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

export default CreateToken;
